.customLogo {
  width: 35px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}
.icon {
  color: #ee8253;
  width: 24px;
  height: 24px;
}

.custombotton {
  background-color: green;
  position: absolute;
  bottom: 0%;
  width: 100%;
}
